import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { TERTIARY_BUTTON_CLASS } from "./constants";
import classNames from "classnames";
import Link from "next/link";

const TertiaryButton = ({
  text,
  simpleText,
  className,
  onClick,
  href,
  style,
  type,
  buttonId,
}) => {
  const textClass = classNames(TERTIARY_BUTTON_CLASS, className);
  const textComponent = simpleText ? (
    <p className={textClass}>{simpleText}</p>
  ) : (
    <PrismicRichText
      field={text}
      components={{
        heading1: ({ children }) => <p className={textClass}>{children}</p>,
        paragraph: ({ children }) => <p className={textClass}>{children}</p>,
      }}
    ></PrismicRichText>
  );
  return href ? (
    (<Link
    href={href}
    passHref
    className={TERTIARY_BUTTON_CLASS + className}
    style={style}
    onClick={onClick}
    id={buttonId}
    data-cy={buttonId}
    prefetch
    >

      {textComponent}

    </Link>)
  ) : (
    <button type={type} onClick={onClick} id={buttonId} data-cy={buttonId}>
      {textComponent}
    </button>
  );
};

export default TertiaryButton;
